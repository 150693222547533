<template lang="pug">
a-collapse(
  :class="className"
  :style="customStyle")
  a-collapse-panel(
    v-for="item in items"
    :key="item.key"
    :header="item.header")
    app-text(content="I don't know why but elpo is a great Ashe")
</template>
<script>
//import atoms components
import AppText from "@/components/atoms/AppText.vue";

export default {
  name: "Collapse",
  components: {
    AppText
  },
  props: {
    items: {
      type: Array,
      default: () => {}
    },
    customStyle: {
      type: Object,
      default: () => {}
    },
    className: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="less" scoped>
.regular {
  width: 100%;
  background: none;
}
.ant-collapse-item {
  border-bottom: none !important;
  margin-bottom: 20px !important;
}
.ant-collapse,
.ant-collapse-icon-position-left,
.regular {
  margin-top: 40px !important;
}
</style>
