<template lang="pug">
layout-sider(
  className="sider-panel"
  :width="200"
  :collapsible="false"
  :customStyle="{margin:'1% 1.4% 0 0%'}")
  app-text(
    :customStyle="{padding: '5%',backgroundColor:'#FAFAFA'}"
    content="Lista de clientes"
    className="regular-bold")
  a-spin
    a-icon(
      slot="indicator"
      type="loading"
      v-show="isLoadingShowRoomComercial"
      style="font-size: 170px" spin)
  div(
    v-show="!isLoadingShowRoomComercial"
    :style="{width: '100%', display: 'flex', alignItems: 'center', margin: '30px 0'}"
    v-for="item in showRoomComercial.attendants")
    a-icon(
      :style="{marginLeft: '5%', fontSize: '18px'}"
      type="right-circle")
    app-text(
      className="small"
      :customStyle="{display: 'inline', margin: '0 0 0 5%', paddingTop:'7.1px'}"
      :content="item.client.name")
</template>
<script>
//import atoms components
import AppText from "@/components/atoms/AppText.vue";

// import organisms components
import LayoutSider from "@/components/organisms/LayoutSider.vue";
import Collapse from "@/components/organisms/Collapse.vue";

//import Vuex
import { mapGetters } from "vuex";

export default {
  name: "SideBarRight",
  components: {
    LayoutSider,
    AppText,
    Collapse
  },
  computed: {
    ...mapGetters(["showRoomComercial", "isLoadingShowRoomComercial", "error"])
  }
};
</script>

<style lang="less">
.ant-layout-sider-children {
  width: 100% !important;
}
</style>
