<template lang="pug">
div(:style="!modalMode ? {width:'100%',paddingRight: '5%',paddingLeft: '2.4%'} : {}")
  app-dropdown
    a-input-search(
      :style="modalMode ? customStyleSearchbar : {}"
      @change="handleQuery"
      @click="()=> {isdropdownVisible = !isdropdownVisible}"
      placeholder="Nombre, descripción, referencia..."
      slot="handler"
      :enter-button="true"
      v-model="query")
  div(
    :class="modalMode ? 'search-overlay-container in-modal' : 'search-overlay-container'"
    @mouseover="()=> {isdropdownVisible = true}"
    :style="isdropdownVisible ?  {...customStyleDropdown, visibility:'visible'} : {}")
      div(:style="{ margin: '0 auto 3%', textAlign: 'left'}")
        app-text(
          content="¿No encuentras lo que buscas?"
          className="regular")
        a(
          @click="showModal"
          href="#")
          app-text(
            :customStyle="{marginLeft: '2%'}"
            content="Búsqueda avanzada"
            className="regular")
        div(:style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}")
          a-spin(
            v-show="isLoadingFilteredCollections")
            a-icon(
              slot="indicator"
              type="loading"
              style="font-size: 170px"
              spin)
      div
        Card(
          v-for="item in displayedCollections"
          :key="item.id"
          className="horizontal")
          div(@click="() => {selected(item)}" class="card-div")
            app-image(
              :url="`${item.images[0] ? item.images[0].url : item.products[0] ? item.products[0].image : '' }`"
              className="input-search-image")
            div(:style="{display:'flex',flexDirection:'column',justifyContent:'space-around'}")
              app-text(
                :customStyle="{width: '100%',marginLeft:'30px'}"
                className="regular-spaced"
                :content="item.name")
              app-text(
                :customStyle="{width: '100%',marginLeft:'30px'}"
                :content="item.brand"
                className="small")
      app-button(
        v-show="collections.next != null && modalMode && query==''"
        :handler="getMoreCollections"
        :loading="isLoadingNextPage"
        :customStyle="{marginLeft:'40px'}"
        label="más colecciones"
        type="primary"
      )
  div(
    v-if="!modalMode"
    :style="isdropdownVisible ? {visibility:'visible'} : {}"
    @click="()=> {isdropdownVisible = !isdropdownVisible}"
    class="fade-block")
  a-modal(
    :closable="false"
    v-model="visible"
    :width="1000"
    :footer="null")
    a-spin(
      v-if="loading")
      a-icon(
        slot="indicator"
        type="loading"
        spin)
    div(
      v-if="!loading")
      div(
        :style="{display: 'flex', justifyContent: 'space-between'}")
        app-h2(
          :customStyle="{margin:'0 0 30px 45px'}"
          content="Buscador Avanzado")
        a-spin(
            v-show="isLoadingFilteredCollections")
            a-icon(
              slot="indicator"
              type="loading"
              spin)
        div(
            :style="{display: 'flex'}"
            v-show="noResults")
            a-icon(
              :style="{color: 'var(--error-color)', margin: '3px 5px 0 0'}"
              type="close-circle")
            app-text(
              :customStyle="{marginRight: '50px'}"
              className="text-error"
              content="No se han encontrado resultados. Reduzca la cantidad de filtros")
      a-input-search(
        v-model="filterQueryString"
        :enter-button="true"
        class= "input-avanzado")
      a-row(:style="{width: '90%', margin: '0 auto'}")
        a-col(
          :style="{margin: '3% 0'}"
          :span="8")
          app-text(content="Color")
          app-select(
            :clear="true"
            :handler="selectedColor"
            :value="filterQuery.products__color__name"
            :options="filter.colors"
            optionFilterProp="children"
            :filterOption="filterOption"
            placeholder="Color"
            :change="clearFilterColorName"
            :showSearch="true")
        a-col(
          :style="{margin: '3% 0'}"
          :span="8")
          app-text(content="Familia")
          app-select(
            :clear="true"
            :handler="selectedFamily"
            :value="filterQuery.products__family__name"
            :options="filter.families"
            optionFilterProp="children"
            :filterOption="filterOption"
            placeholder="Familia"
            :showSearch="true"
            :change="clearFilterFamilyName")
        a-col(
          :style="{margin: '3% 0'}"
          :span="8")
          app-text(content="Característica")
          app-select(
            :clear="true"
            :handler="selectedFeature"
            :value="filterQuery.products__feature__name"
            :options="filter.features"
            optionFilterProp="children"
            :filterOption="filterOption"
            placeholder="Característica"
            :showSearch="true"
            :change="clearFilterFeatureName")
        a-col(
          :span="8")
          app-text(content="Formato")
          app-select(
            :clear="true"
            :handler="selectedFormat"
            :value="filterQuery.products__format_cm__name"
            :options="filter.formatCM"
            optionFilterProp="children"
            :filterOption="filterOption"
            placeholder="Formato"
            :showSearch="true"
            :change="clearFilterFormatName")
        a-col(
          :span="8")
          app-text(content="Matiz")
          app-select(
            :clear="true"
            :handler="selectedNuance"
            :value="filterQuery.products__nuance__name"
            :options="filter.nuances"
            optionFilterProp="children"
            :filterOption="filterOption"
            placeholder="Matiz"
            :showSearch="true"
            :change="clearFilterNuanceName")
        a-col(
          :span="8")
          app-text(content="Solución")
          app-select(
            :clear="true"
            :handler="selectedSolution"
            :value="filterQuery.products__solutions__name"
            :options="filter.solutions"
            optionFilterProp="children"
            :filterOption="filterOption"
            placeholder="Solución"
            :showSearch="true"
            :change="clearFilterSolutionName")
        a-col(
          :style="{margin: '30px 30px 30px 0'}"
          :span="10")
          app-text(
            :customStyle="{margin: '0 0 30px'}"
            content="Otros")
          a-row(class="checkbox-container")
            a-col(:span="12")
              a-checkbox(
                :checked="checkedAntiSlip"
                @change="selectedAntiSlip") Antideslizante
            a-col(:span="12")
              a-checkbox(
                :checked="checkedRectified"
                @change="selectedRectified") Rectificado
            a-col(:span="12")
              a-checkbox(
                :checked="checkedIsNew"
                @change="selectedIsNew") Nuevo
            a-col(:span="12")
              a-checkbox(
                :checked="checkedIsSpecial"
                @change="selectedIsSpecial") Especial
        a-col(
          :style="{margin: '30px'}"
          :span="10")
          app-text(
            :customStyle="{margin: '0 0 30px'}"
            content="Usos")
          a-row(class="checkbox-container")
            a-col(:span="12")
              a-checkbox(
                :checked="checkedPavRev"
                :disabled="checkPavRev"
                @change="selectedPavRev") Pav/Rev
            a-col(:span="12")
              a-checkbox(
                :checked="checkedRev"
                :disabled="checkRev"
                @change="selectedRev") Revestimento
            a-col(:span="12")
              a-checkbox(
                :disabled="checkPav"
                :checked="checkedPav"
                @change="selectedPav") Pavimento
      div(:style="{display: 'flex', justifyContent: 'flex-end', paddingRight:'100px'}")
        app-button(
          className="medium"
          label="Limpiar"
          :handler="clearFilters")
        app-button(
          :customStyle="{marginLeft: '2%'}"
          className="medium"
          type="primary"
          :handler="avancedSearch"
          label="Filtrar")
</template>
<script>
//import assets
import image from "@/assets/ImageMock.jpg";

//import atoms components
import AppButton from "@/components/atoms/AppButton.vue";
import AppImage from "@/components/atoms/AppImage.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppH2 from "@/components/atoms/AppH2.vue";
import AppSelect from "@/components/atoms/AppSelect.vue";

//import molecules components
import Card from "@/components/molecules/Card.vue";

//import third part components
import { Fragment } from "vue-fragment";

//import custom components of this component
import AppDropdown from "@/components/atoms/AppDropdown.vue";

//Import Store actions
import {
  UPDATE_VIEWER_ITEM,
  FETCH_FILTERED_COLLECTIONS,
  FETCH_COLLECTIONS,
  PAGINATION
} from "@/store/actions.type";

//Vuex imports
import { mapGetters } from "vuex";

import { ProductFormService } from "@/common/api.service";

export default {
  name: "SearchInput",
  components: {
    AppButton,
    Fragment,
    AppDropdown,
    Card,
    AppImage,
    AppH2,
    AppText,
    AppSelect
  },
  props: {
    noMargin: {
      type: Boolean,
      default: false
    },
    modalMode: {
      type: Boolean,
      default: false
    },
    customStyleSearchbar: {
      type: Object,
      default: () => {}
    },
    customStyleDropdown: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      image,
      noResults: false,
      visible: false,
      loading: true,
      isdropdownVisible: false,
      query: "",
      filter: {},
      filterQueryString: undefined,
      checkPavRev: false,
      checkRev: false,
      checkPav: false,
      checkedPavRev: false,
      checkedRev: false,
      checkedPav: false,
      checkedAntiSlip: false,
      checkedRectified: false,
      checkedIsNew: false,
      checkedIsSpecial: false,
      filterQuery: {
        products__format_cm__name: undefined,
        products__nuance__name: undefined,
        products__color__name: undefined,
        products__solutions__name: undefined,
        products__family__name: undefined,
        products__feature__name: undefined,
        products__anti_slip: undefined,
        products__rectified: undefined,
        products__use: undefined,
        products__new: undefined,
        products__is_special: undefined
      }
    };
  },
  computed: {
    showDropdown() {
      return this.query.length >= 2;
    },
    ...mapGetters([
      "filteredCollections",
      "isLoadingFilteredCollections",
      "collections",
      "isLoadingNextPage"
    ]),
    displayedCollections() {
      if (this.modalMode && this.query == "") {
        return this.$store.getters.collections.results;
      } else {
        return this.filteredCollections;
      }
    }
  },
  watch: {
    activateModal() {
      this.visible = false;
    }
  },
  async beforeMount() {
    const colors = await ProductFormService.getColors();
    const families = await ProductFormService.getFamilies();
    const features = await ProductFormService.getFeatures();
    const formatCM = await ProductFormService.getFormatCM();
    const nuances = await ProductFormService.getNuances();
    const solutions = await ProductFormService.getSolutions();

    let data = colors.data;
    this.filter.colors = this.transformDataToSelect(data);

    data = families.data;
    this.filter.families = this.transformDataToSelect(data);

    data = features.data;
    this.filter.features = this.transformDataToSelect(data);

    data = formatCM.data;
    this.filter.formatCM = this.transformDataToSelect(data);

    data = nuances.data;
    this.filter.nuances = this.transformDataToSelect(data);

    data = solutions.data;
    this.filter.solutions = this.transformDataToSelect(data);

    this.loading = false;
  },
  mounted() {
    this.$store.dispatch(FETCH_COLLECTIONS);
  },
  methods: {
    clearFilterColorName(e) {
      if (!e) {
        this.filterQuery.products__color__name = undefined;
      }
    },
    clearFilterFamilyName(e) {
      if (!e) {
        this.filterQuery.products__family__name = undefined;
      }
    },
    clearFilterFeatureName(e) {
      if (!e) {
        this.filterQuery.products__feature__name = undefined;
      }
    },
    clearFilterFormatName(e) {
      if (!e) {
        this.filterQuery.products__format_cm__name = undefined;
      }
    },
    clearFilterNuanceName(e) {
      if (!e) {
        this.filterQuery.products__nuance__name = undefined;
      }
    },
    clearFilterSolutionName(e) {
      if (!e) {
        this.filterQuery.products__solutions__name = undefined;
      }
    },
    getMoreCollections() {
      this.$store.dispatch(PAGINATION);
    },
    disapearDropdown() {
      setTimeout(() => {
        this.isdropdownVisible = false;
      }, 3000);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    showModal() {
      this.query = "";
      this.visible = true;
    },
    handleQuery() {
      if (this.query !== "") {
        this.$store.dispatch(FETCH_FILTERED_COLLECTIONS, { query: this.query });
      }
    },
    selected(item) {
      this.isdropdownVisible = false;
      if (this.modalMode) {
        this.$emit("collection-preselected", item);
      } else {
        this.$store.dispatch(UPDATE_VIEWER_ITEM, item);
      }
    },
    transformDataToSelect(data) {
      return data.map(element => {
        return { text: element.name, value: element.name };
      });
    },
    selectedAntiSlip(e) {
      if (e.target.checked === true) {
        this.checkedAntiSlip = true;
        this.filterQuery.products__anti_slip = true;
      } else {
        this.checkedAntiSlip = false;
        this.filterQuery.products__anti_slip = undefined;
      }
    },
    selectedRectified(e) {
      if (e.target.checked === true) {
        this.checkedRectified = true;
        this.filterQuery.products__rectified = true;
      } else {
        this.checkedRectified = false;
        this.filterQuery.products__rectified = undefined;
      }
    },
    selectedIsNew(e) {
      if (e.target.checked === true) {
        this.checkedIsNew = true;
        this.filterQuery.products__new = true;
      } else {
        this.checkedIsNew = false;
        this.filterQuery.products__new = undefined;
      }
    },
    selectedIsSpecial(e) {
      if (e.target.checked === true) {
        this.checkedIsSpecial = true;
        this.filterQuery.products__is_special = true;
      } else {
        this.checkedIsSpecial = false;
        this.filterQuery.products__is_special = undefined;
      }
    },
    selectedPavRev(e) {
      if (e.target.checked === true) {
        this.checkedPavRev = true;
        this.checkRev = true;
        this.checkPav = true;
        this.filterQuery.products__use = "PAV/REV";
      } else {
        this.checkedPavRev = false;
        this.checkRev = false;
        this.checkPav = false;
        this.filterQuery.products__use = undefined;
      }
    },
    selectedRev(e) {
      if (e.target.checked === true) {
        this.checkedRev = true;
        this.checkPavRev = true;
        this.checkPav = true;
        this.filterQuery.products__use = "REV";
      } else {
        this.checkedRev = false;
        this.checkPavRev = false;
        this.checkPav = false;
        this.filterQuery.products__use = undefined;
      }
    },
    selectedPav(e) {
      if (e.target.checked === true) {
        this.checkedPav = true;
        this.checkPavRev = true;
        this.checkRev = true;
        this.filterQuery.products__use = "PAV";
      } else {
        this.checkedPav = false;
        this.checkPavRev = false;
        this.checkRev = false;
        this.filterQuery.products__use = undefined;
      }
    },
    avancedSearch() {
      this.$store
        .dispatch(FETCH_FILTERED_COLLECTIONS, {
          filterQuery: this.filterQuery,
          query: this.filterQueryString
        })
        .then(data => {
          if (data.length > 0) {
            this.visible = false;
            this.noResults = false;
            this.$emit("filtered-by", this.filterQuery, this.filterQueryString);
          } else {
            this.noResults = true;
          }
        });
    },
    clearFilters() {
      this.filterQuery.products__format_cm__name = undefined;
      this.filterQuery.products__nuance__name = undefined;
      this.filterQuery.products__color__name = undefined;
      this.filterQuery.products__solutions__name = undefined;
      this.filterQuery.products__family__name = undefined;
      this.filterQuery.products__feature__name = undefined;
      this.filterQuery.products__new = undefined;
      this.filterQuery.products__use = undefined;
      this.filterQuery.products__anti_slip = undefined;
      this.filterQuery.products__rectified = undefined;
      this.filterQuery.products__is_special = undefined;
      this.filterQueryString = undefined;
      this.checkPavRev = false;
      this.checkPav = false;
      this.checkRev = false;
      this.checkedPavRev = false;
      this.checkedPav = false;
      this.checkedRev = false;
      this.checkedAntiSlip = false;
      this.checkedRectified = false;
      this.checkedIsNew = false;
      this.checkedIsSpecial = false;
      this.displayedColors = [];
      this.displayedSolutions = [];
      this.displayedNuances = [];
      this.displayedFormatCM = [];
      this.displayedFeatures = [];
      this.displayedFamilies = [];
      this.noResults = false;
    },
    selectedColor(color) {
      this.filterQuery.products__color__name = color;
    },
    selectedFamily(family) {
      this.filterQuery.products__family__name = family;
    },
    selectedFeature(feature) {
      this.filterQuery.products__feature__name = feature;
    },
    selectedFormat(format) {
      this.filterQuery.products__format_cm__name = format;
    },
    selectedNuance(nuance) {
      this.filterQuery.products__nuance__name = nuance;
    },
    selectedSolution(solution) {
      this.filterQuery.products__solutions__name = solution;
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../../less/main.less";

.checkbox-container {
  background: @contrast-background-color;
  padding: 5%;
  width: 387.5px;
}
.ant-select {
  width: 235px !important;
}
span.ant-input-search {
  padding: 15px 0;
}

.search-overlay-container {
  display: block;
  visibility: hidden;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  max-width: 790px !important;
  border-top: solid 22px var(--container-color);
  top: 53px;
  left: 50px;
  right: 0;
  text-align: center;
  z-index: 100;
  height: 369px;
  overflow: scroll;
  background: var(--gray-color);
  padding: 30px;
}
.in-modal {
  border: 0;
  left: 0;
  right: 0;
  top: 353px;
  width: auto;
}
.fade-block {
  visibility: hidden;
  height: 97vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 10;
  top: 75px;
  left: 0;
}
.ant-input-group-wrapper {
  margin: 0 2.5% 0 5%;
}
.input-avanzado {
  max-width: 805px !important;
}

.container-input-checkbox {
  background: var(--contrast-background-color);
  width: 387.5px !important;
  padding: 1%;
  margin: 0 30px;
}
.border-right {
  border-right: 1px solid var(--black-color);
  margin-right: 30px;
}

.container-input {
  background: var(--contrast-background-color);
  padding: 0.5%;
}
.horizontal {
  cursor: pointer;
  text-align: left !important;
  margin: 30px auto;
  width: 100%;
}
.card-div {
  display: flex !important;
}
</style>
