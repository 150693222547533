function imageHandler(collections) {
  const { images, products, brochures } = collections;
  if (images === [] && products === [] && brochures === []) {
    return "";
  } else if (images.length > 0) {
    return images[0].url;
  } else if (products.length > 0) {
    return products[0].image;
  } else if (brochures.length > 0) {
    return brochures[0].url;
  } else {
    return "";
  }
}
export default imageHandler;
