<template lang="pug">
    div(
      :style="customStyle"
      ref="draggableContainer"
      id="draggable-container")
      div(
        id="draggable-header"
        @mousedown="dragMouseDown")
        slot(name="header")
      slot(name="main")
      slot(name="footer")
</template>

<script>
export default {
  name: "DraggableDiv",
  props: {
    customStyle: {
      type: Object,
      default: () => {}
    },
    reset: {
      type: Boolean,
      default: false
    },
    pointOrigin: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    };
  },
  updated() {
    if (this.reset) {
      this.resetDraggable();
    }
  },
  methods: {
    resetDraggable() {
      this.positions.movementX = this.pointOrigin.originX;
      this.positions.movementY = this.pointOrigin.originY;
      this.positions.clientX = this.pointOrigin.originX;
      this.positions.clientY = this.pointOrigin.originY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top = this.positions.movementY + "px";
      this.$refs.draggableContainer.style.left =
        this.positions.movementX + "px";
      this.$emit("reset-true");
    },
    dragMouseDown: function(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
};
</script>

<style>
#draggable-container {
  position: absolute;
  left: 60%;
  top: 55%;
  z-index: 9;
  overflow: hidden;
  resize: both;
  min-width: 500px;
  min-height: 300px;
  height: 300px;
}
#draggable-container div:nth-child(2) {
  height: 90%;
  width: 100%;
}
#draggable-header {
  z-index: 10;
}
</style>
