<template lang="pug">
layout-content.scroll-container
  a-skeleton(
    v-if="isLoadingShowRoomComercial",
    :style="{ width: '90%', margin: '0 auto' }",
    :active="isLoadingShowRoomComercial",
    :loading="isLoadingShowRoomComercial"
  )
  a-skeleton(
    v-if="isLoadingShowRoomComercial",
    :style="{ width: '90%', margin: '0 auto' }",
    :active="isLoadingShowRoomComercial",
    :loading="isLoadingShowRoomComercial"
  )
  a-skeleton(
    v-if="isLoadingShowRoomComercial",
    :style="{ width: '90%', margin: '0 auto' }",
    :active="isLoadingShowRoomComercial",
    :loading="isLoadingShowRoomComercial"
  )
  a-skeleton(
    v-if="isLoadingShowRoomComercial",
    :style="{ width: '90%', margin: '0 auto' }",
    :active="isLoadingShowRoomComercial",
    :loading="isLoadingShowRoomComercial"
  )
  span(v-show="true")
    div(
      v-show="filtersUsed !== ''"
      :style="{ padding: '0 5%', display: 'flex', justifyContent: 'space-between' }")
      a(href="#")
        app-text(
          @click="filtersClicked()"
          :content="filtersUsed")
    div(:style="{ padding: '0 5%', display: 'flex', justifyContent: 'space-between' }")
      app-h2(className="regular", :content="viewerItem.collection.name")
      app-button(
        :handler="() => { sendItem(); }",
        label="Enviar",
        icon="arrow-right",
        type="primary"
      )
    div(v-if="viewerItem.media === 'video'",:style="{background:'transparent !important',padding:'0.5rem 5%'}")
        a-switch(v-model="videoControlling",:style="{marginRight:'1rem'}")
        span Vídeo controlado
    a-tabs(
      :active-key="viewerItem.media",
      :key="viewerItem.media"
      :tabBarStyle="{ display: 'none' }",
      :style="{ width: '90%', margin: '10px auto 0', background:'#e2e2e2 !important', display:'grid !important', justifyContent:'center' }"
    )
      a-tab-pane(tab="Imagen", key="image")
        AppImageHd(
          :customStyle="{ objectFit: 'scale-down', cursor: 'pointer', maxHeight: '50vh' }",
          :url="viewerItem.url"
        )
      a-tab-pane(tab="Video", key="video")
        app-video(
            :key="viewerItem.url"
            ref="video",
            className="video-viewer"
            @paused="onPause",
            @playing="onPlay",
            @buffering="onBuffering"
            @volumechange="onVolumeChange"
            :width="1000",
            :height="700",
            :idVideo="viewerItem.url"
          )
    a-tabs(
      :style="{ width: '90%', margin: '0 auto' }",
      default-active-key="product"
    )
      a-tab-pane(key="product", tab="Productos")
        carousel(
          :per-page="3",
          :mouse-drag="true",
          :navigationEnabled="true",
          :speed="800",
          :centerMode="true",
          :loop="true"
        )
          slide(v-for="item in viewerItem.collection.products", :key="item.id")
            div(@click="() => { selected({ media: 'image', url: item.image, id: item.id, description: item.description }); }")
              Card(:className="isActive === item.id ? 'panel active-product' : 'panel'")
                app-image(className="carousel-image" :url="item.image" :customStyle="{width: '100% !important'}")
                app-text(
                  :customStyle="{ margin: '-25px auto 0',fontSize: '13px !important',fontWeight: '500 !important',height:'35px',padding:'10px'}"
                  :content="charLimiter(item.description, 50)"
                )
      a-tab-pane(key="brochure", tab="Brochures")
        carousel(
          :per-page="3",
          :mouse-drag="true",
          :navigationEnabled="true",
          :speed="800",
          :centerMode="true",
          :loop="true"
        )
          slide(
            v-for="item in viewerItem.collection.brochures",
            :key="item.id"
          )
            div(@click="() => { selected({ media: 'image', url: item.url , id: item.id}); }")
              Card(:className="isActive === item.id ? 'panel active-product' : 'panel'")
                app-image(className="carousel-image", :url="item.url")
                app-text(
                  :customStyle="{ marginTop: '2%' }",
                  className="small",
                  :content="item.brand"
                )
      a-tab-pane(key="image", tab="Ambientes")
        carousel(
          :per-page="3",
          :mouse-drag="true",
          :navigationEnabled="true",
          :speed="800",
          :centerMode="true",
          :loop="true"
        )
          slide(v-for="item in viewerItem.collection.images", :key="item.id")
            div(@click="() => { selected({ media: 'image', url: item.url , id: item.id}); }")
              Card(:className="isActive === item.id ? 'panel active-product' : 'panel'")
                app-image(className="carousel-image", :url="item.url" :customStyle="{marginRight:'10px'}")
      a-tab-pane(key="dataSheet", tab="Fichas")
        carousel(
          :per-page="3",
          :mouse-drag="true",
          :navigationEnabled="true",
          :speed="800",
          :centerMode="true",
          :loop="true"
        )
          slide(
            v-for="item in viewerItem.collection.sheets",
            :key="item.id"
          )
            div(@click="() => { selected({ media: 'image', url: item.url,id: item.id }); }")
              Card(:className="isActive === item.id ? 'panel active-product' : 'panel'")
                app-image(className="carousel-image", :url="item.url" :customStyle="{marginRight:'10px'}")
      a-tab-pane(key="video", tab="Vídeos")
        carousel(
          :per-page="3",
          :mouse-drag="true",
          :navigationEnabled="true",
          :speed="800",
          :centerMode="true",
          :loop="true"
        )
          slide(
            :style="{ margin: '1%' }",
            v-for="(item, id) in viewerItem.collection.videos",
            :key="item.id"
          )
            div(
              @click="() => { selected({ media: 'video', url: item.youtube_id,id: item.id }); }"
              :style="{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'column', alignItems: 'center' }"
            )
              Card(:className="isActive === item.id ? 'panel active-product' : 'panel'")
                app-video(
                  :width="250",
                  :height="180",
                  :idVideo="item.youtube_id"
                  v-on:get-miniature="OnImg"
                  :getImg="true"
                )
                app-image(
                  :customStyle="{maxWidth: '246px'}"
                  :url="String(miniatures[id])" )
  </template>
<script>
//import assets
import image from "@/assets/ImageMock.jpg";

//import atoms components
import AppButton from "@/components/atoms/AppButton.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppImage from "@/components/atoms/AppImage.vue";
import AppH2 from "@/components/atoms/AppH2.vue";
import AppVideo from "@/components/atoms/AppVideo.vue";
import AppImageHd from "@/components/atoms/AppImageHd.vue";

//import molecules components
import Card from "@/components/molecules/Card.vue";

//import organisms components
import LayoutContent from "@/components/organisms/LayoutContent.vue";

//import third part components
import { Carousel, Slide } from "vue-carousel";

// Import for Vuex Utils
import { mapGetters } from "vuex";

//Import Store actions
import { UPDATE_VIEWER_MEDIA } from "@/store/actions.type";

//Import Api url
import { WEBSOCKET_URL } from "@/common/config.js";

import { SalesRoomService } from "@/common/api.service.js";

//Import jwt service

import JwtService from "@/common/jwt.service";

export default {
  name: "Viewer",
  components: {
    AppButton,
    AppText,
    AppImage,
    AppH2,
    AppVideo,
    Carousel,
    Slide,
    LayoutContent,
    Card,
    AppImageHd
  },
  props: {
    filtersUsed: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      image,
      miniatures: [],
      isActive: 0,
      videoControlling: true
    };
  },
  computed: {
    ...mapGetters(["viewerItem", "isLoadingShowRoomComercial"])
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.viewerItem.media === "video") {
        this.$socket.sendObj({
          type: "video_message",
          action: "control-change",
          controlValue: this.videoControlling,
          video: this.viewerItem.url
        });
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    this.isActive = this.viewerItem.id;
  },
  beforeCreate() {
    //Create a connection with backend, sending the id of the room
    this.$connect(
      `${WEBSOCKET_URL}/salesrooms_seller/${
        this.$route.params.id
      }/?token=${JwtService.getToken()}`,
      {
        format: "json"
      }
    );
    // Message Listener
    this.$options.sockets.onmessage = data => console.log(data);

    this.sendItem();
  },
  methods: {
    charLimiter(str, limit) {
      return str.length >= limit ? `${str.substr(0, limit)}...` : str;
    },
    OnImg(miniature) {
      this.miniatures.push(miniature);
    },
    filtersClicked() {
      this.$emit("filters-clicked");
    },
    onVolumeChange(volume) {
      if (!this.videoControlling) return;
      this.$socket.sendObj({
        type: "video_message",
        action: "setvolume",
        volume: volume,
        video: this.viewerItem.url
      });
    },
    onBuffering(time) {
      if (!this.videoControlling) return;
      this.$socket.sendObj({
        type: "video_message",
        action: "buffering",
        video: this.viewerItem.url,
        time: time
      });
    },
    onPause(time) {
      if (!this.videoControlling) return;
      this.$socket.sendObj({
        type: "video_message",
        action: "pause",
        video: this.viewerItem.url,
        time: time
      });
    },
    onPlay(time) {
      if (!this.videoControlling) return;
      this.$socket.sendObj({
        type: "video_message",
        action: "play",
        video: this.viewerItem.url,
        time: time
      });
    },
    selected(selectedItem) {
      const { id } = selectedItem;
      this.isActive = id;
      this.$store.dispatch(UPDATE_VIEWER_MEDIA, selectedItem);
    },
    sendItem() {
      SalesRoomService.setShowingContent(this.$route.params.id, {
        media: this.viewerItem.media,
        url: this.viewerItem.url,
        serie: this.viewerItem.collection.name,
        description: this.viewerItem.description
      });
      this.$socket.sendObj({
        type: "product_message",
        product: "ViewerItem",
        media: this.viewerItem.media,
        url: this.viewerItem.url,
        serie: this.viewerItem.collection.name,
        description: this.viewerItem.description
      });
      this.$message.success("Contenido enviado", 2);
    },
    selectedItem(id) {
      return this.isActive === id ? "panel active-product" : "panel";
    }
  }
};
</script>
<style lang="less">
.panel {
  cursor: pointer;
}

// .panel div:nth-child(1) {
//   width: 99%;
// }

.carousel-image[data-v-db8cbed2] {
  width: 99%;
}
.active-product {
  border: 2px solid var(--primary-color);
}
.VueCarousel-dot {
  background-color: var(--black-color) !important;
}
.VueCarousel-dot--active {
  background-color: var(--primary-color) !important;
}
.active-product p {
  color: var(--primary-color) !important;
  font-weight: bold;
}
.ant-tabs-tab-active {
  color: var(--primary-color) !important;
}
.ant-tabs-tab-active:hover {
  color: var(--primary-color) !important;
}
.ant-tabs-ink-bar {
  background-color: var(--primary-color) !important;
}
.scroll-container {
  max-height: 80vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.carousel-image {
  cursor: pointer;
  width: 99%;
}
.VueCarousel-inner {
  justify-content: flex-start !important;
}
</style>
