<template lang="pug">
  div.container
    youtube(
      :style="customStyle"
      :video-id="VideoID"
      ref="youtube"
      :playerVars="playerVars"
      :resize="resize"
      :class="className"
      @buffering="onBuffering"
      @ready="ready"
      @paused="onPause"
      @playing="playing"
      :fitParent="true"
      v-if="!getImg")
    div.panel(:style="{'pointer-events': !controlled ? 'none' : 'initial'}")
</template>
<script>
/*Get Id Video youtube */
import getYouTubeID from "get-youtube-id";

export default {
  name: "AppVideo",
  props: {
    resize: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 300
    },
    url: {
      /*To get the video id*/
      type: String,
      default: ""
    },
    idVideo: {
      type: String,
      default: null
    },
    className: {
      type: String,
      default: ""
    },
    videoAdd: {
      /*Add Video To The Backend*/
      type: Boolean,
      default: false
    },
    getImg: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: Object,
      default: () => {}
    },
    controlled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      VideoID: "",
      playerVars: {
        rel: 0
      }
    };
  },
  watch: {
    controlled(value) {
      if (value)
        this.playerVars = {
          showinfo: 0,
          modestbranding: 1,
          rel: 0,
          controls: 0,
          disablekb: 1
        };
      else
        this.playerVars = {
          rel: 0
        };
    },
    url: function(newVal, oldVal) {
      if (oldVal != newVal) {
        this.VideoID = getYouTubeID(newVal);
        this.$refs.youtube.player.loadVideoById(this.VideoID);
      }
    },
    idVideo: function(newVal, oldVal) {
      if (oldVal != newVal) {
        this.VideoID = newVal;
        this.$refs.youtube.player.loadVideoById(this.VideoID);
      }
    }
  },
  created() {
    if (!this.getImg) {
      if (this.idVideo != null) {
        this.VideoID = this.idVideo;
      } else {
        this.VideoID = getYouTubeID(this.url);
      }
    } else if (this.url) {
      this.VideoID = getYouTubeID(this.url);
      if (this.VideoID == null) {
        this.VideoID = this.url;
      }
    } else {
      this.VideoID = this.idVideo;
    }

    this.getMiniature();
  },
  async mounted() {
    if (!this.getImg) {
      let volume = null; //clousure variables 😎
      this.checkVolumeInterval = setInterval(async () => {
        let isMuted = await this.$refs.youtube.player.isMuted();
        let newVolume = null;
        if (isMuted) newVolume = 0;
        else newVolume = await this.$refs.youtube.player.getVolume();
        if (volume !== newVolume) {
          this.$emit("volumechange", newVolume);
          volume = newVolume; //clousure variables 😎
        }
      }, 250);
    }
  },
  beforeDestroy() {
    clearInterval(this.checkVolumeInterval);
  },
  methods: {
    async onPause() {
      let time = await this.$refs.youtube.player.getCurrentTime();
      this.$emit("paused", time);
    },
    async onBuffering() {
      let time = await this.$refs.youtube.player.getCurrentTime();
      this.$emit("buffering", time);
    },
    setVolume(volume) {
      this.$refs.youtube.player.setVolume(volume);
    },
    pauseVideo(time) {
      if (time !== null && time !== undefined)
        this.$refs.youtube.player.seekTo(time);
      this.$refs.youtube.player.pauseVideo();
    },
    playVideo(time) {
      if (time !== null && time !== undefined)
        this.$refs.youtube.player.seekTo(time);
      this.$refs.youtube.player.playVideo();
    },
    async playing() {
      let time = await this.$refs.youtube.player.getCurrentTime();
      this.$emit("playing", time);
      if (this.VideoAdd) {
        /*verify that the video is not added*/
        /*  this.$emit("VideoAdd", {
          name: this.$refs.youtube.player.getVideoData().title,
          descripction: this.$refs.youtube.player.getVideoData().descripction
        }); */
      }
    },
    ready() {
      this.$refs.youtube.player.setSize(this.width, this.height);
    },
    getMiniature() {
      /*return the video thumbnail */
      this.$emit(
        "get-miniature",
        "https://img.youtube.com/vi/" + this.VideoID + "/0.jpg"
      );
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../less/main.less";

.panel {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.container {
  z-index: 0;
  position: relative;
}
</style>
