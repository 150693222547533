<template lang="pug">
div(
  v-viewer
  ref="viewer"
  :options="options"
  rebuild
  @inited="inited"
  class="viewer")
  a-spin(v-if="!loaded" class="image-loader" size="large")
  img(
    v-show="loaded"
    @load = "onImgLoad"
    :style="customStyle"
    :src="url")
</template>
<script>
export default {
  name: "AppImageHd",
  props: {
    url: {
      type: String,
      required: true
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      loaded: false,
      options: {
        inline: false,
        button: true,
        navbar: true,
        title: false,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: false,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: "data-source"
      }
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    onImgLoad() {
      this.loaded = true;
    }
  }
};
</script>
<style lang="less">
.image-loader {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
