<template lang="pug">
a-dropdown(
  v-model="visible")
  slot(name="handler")
  slot(
    name="overlay"
    slot="overlay")
</template>
<script>
export default {
  name: "AppDropdown",
  data() {
    return {
      visible: false
    };
  }
};
</script>
