<template lang="pug">
div(
  :class="className"
  :style="customStyle")
  slot
</template>
<script>
export default {
  name: "Card",
  props: {
    className: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";

.regular {
  width: 248.94px;
  height: 177px;
  background: var(--white-color);
}

.sidebar {
  width: 209.95px;
  height: 160.89px;
  background: var(--white-color);
}

.horizontal {
  width: 582.23px;
  height: 118px;
  background: var(--white-color);
  margin: 1% auto;
  display: flex;
}

.panel {
  width: 248.94px;
  height: 200px;
  background: var(--white-color);
  display: grid;
  justify-content: center;
  align-items: space-around;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  margin-right: 10px;
}
.panel:hover {
  background: var(--gray-color);
}

.dashboard-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 450px;
  height: 146px;
  background-color: white;
}
</style>
