<template lang="pug">
layout-sider(
  :customStyle="{justifyContent: 'center'}"
  className="sider-panel"
  :width="270"
  :collapsible="false")
  app-select(
    :style="{margin: '10px 0 10px 20px'}"
    :handler="smallSearch"
    :value="selected"
    :options="[{text: 'Preseleccionado', value: 'Preseleccionado'},{text: 'Todas las colecciones', value: 'Todas las colecciones'},{text: 'Resultados', value: 'Resultados'}]")
  div(:style="{padding: '0',margin: '5%', height: '80vh', overflow: 'scroll', overflowX: 'hidden'}")
    a-spin
      a-icon(
        slot="indicator"
        type="loading"
        v-show="isLoadingShowRoomComercial || isLoadingFilteredCollections"
        style="font-size: 170px"
        spin)
    Card(
      v-if="!isLoadingShowRoomComercial || !isLoadingFilteredCollections"
      :customStyle="{margin: '0 auto 15%', cursor:'pointer'}"
      v-for="item in displayedCollections"
      :key="item.id"
      :className="item.id === viewerItem.id ? 'sidebar is-collection-active' : 'sidebar'")
      div(@click="() => {updateViewer(item)}")
        app-image(
          className="sidebar-image"
          :url="imageHandler(item)")
        app-text(
          :style="{display:'block', textAlign: 'center', margin: '5% auto'}"
          :content="item.name"
          className="small")
    app-button(
        v-if="this.selected ==='Todas las colecciones' && nextPage != null"
        :customStyle="{marginLeft:'40px', marginBottom:'60px'}"
        :handler="() => {getMoreCollections(nextPage)}"
        label="más colecciones"
        type="primary"
        :loading="isLoadingNextPage"
      )
    app-button(
        v-if="this.selected ==='Resultados' && nextPageFiltered != null"
        :customStyle="{marginLeft:'40px', marginBottom:'60px'}"
        :handler="() => {getMoreCollections(nextPageFiltered, true)}"
        label="más colecciones"
        type="primary"
        :loading="isLoadingNextPage"
      )
</template>
<script>
//import atoms components
import AppSelect from "@/components/atoms/AppSelect.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppImage from "@/components/atoms/AppImage.vue";
import AppButton from "@/components/atoms/AppButton.vue";
//import molecules components
import Card from "@/components/molecules/Card.vue";

//import organisms components
import LayoutSider from "@/components/organisms/LayoutSider.vue";

//Vuex imports
import { mapGetters } from "vuex";

//Import Store actions
import { UPDATE_VIEWER_ITEM } from "@/store/actions.type";

//import helpers
import imageHandler from "@/common/imageHandler.js";
import { PAGINATION } from "../../../store/actions.type";

export default {
  name: "SideBarLeft",
  components: {
    LayoutSider,
    AppSelect,
    AppText,
    AppImage,
    Card,
    AppButton
  },
  data() {
    return {
      selected: "Preseleccionado"
    };
  },
  computed: {
    ...mapGetters([
      "showRoomComercial",
      "isLoadingShowRoomComercial",
      "isLoadingFilteredCollections",
      "collections",
      "isLoadingNextPage",
      "viewerItem",
      "nextPageFiltered",
      "nextPage"
    ]),
    displayedCollections() {
      if (this.selected == "Resultados") {
        return this.$store.getters.filteredCollections;
      } else if (this.selected === "Preseleccionado") {
        return this.showRoomComercial.scheduled_series;
      } else if (this.selected === "Todas las colecciones") {
        return this.$store.getters.collections.results;
      } else {
        return [];
      }
    }
  },
  updated() {
    if (this.isLoadingFilteredCollections) {
      this.selected = "Resultados";
    }
  },
  // mounted() {
  //   this.$store.dispatch(FETCH_COLLECTIONS);
  // },
  methods: {
    updateViewer(item) {
      this.$store.dispatch(UPDATE_VIEWER_ITEM, item);
    },
    smallSearch(value) {
      this.selected = value;
    },
    getMoreCollections(next, isFilter) {
      const payload = { next, isFilter };
      this.$store.dispatch(PAGINATION, payload);
    },
    imageHandler
  }
};
</script>
<style lang="less">
.is-collection-active {
  color: var(--primary-color);
  font-weight: bold !important;
  border: 2px solid var(--primary-color);
}
</style>
