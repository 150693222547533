<template lang="pug">
a-select(
  :allowClear="clear"
  :defaultValue="label"
  :value="value"
  @select="handler"
  :style="{customStyle}"
  :show-search="showSearch"
  :option-filter-prop="optionFilterProp"
  :placeholder="placeholder"
  :filter-option="filterOption"
  @change="change")
  a-select-option(
    v-for="item in options"
    :key="item.value"
    :value="item.value") {{item.text}}
</template>
<script>
export default {
  name: "AppSelect",
  props: {
    options: {
      type: Array,
      default: () => {}
    },
    label: {
      type: String,
      default: "Seleciona una opción"
    },
    handler: {
      type: Function,
      default: () => {}
    },
    customStyle: {
      type: Object,
      default: () => {}
    },
    className: {
      type: String,
      default: ""
    },
    clear: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    optionFilterProp: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    filterOption: {
      type: Function,
      default: () => {}
    },
    change: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
.ant-select {
  width: 235px !important;
}
</style>
