<template lang="pug">
Layout
  Header
    search-input(
      ref="searchInput"
      v-on:filtered-by="showFiltersUsed")
    div(
      @click="endShowRoom"
      :style="{display: 'flex', margin: '0 3% 0 1%', cursor: 'pointer'}")
      app-text(
        :customStyle="{marginRight: '3%'}"
        className="regular-light"
        class="container-navbar-text"
        content="Showroom")
      app-text(
        className="regular-error"
        class="container-navbar-text--danger"
        content=" Finalizar")
  Layout(:style="{marginTop: '2%'}")
    draggable-div(
      v-if="is_in_call"
      :reset="resetDraggableDivFlag"
      :pointOrigin="{originX, originY}"
      v-on:reset-true="resetDraggableDiv(false)")
      template(slot="header")
        a-button(
          type="primary"
          @click="clickDragButton"
          size="small") Arrastrar
        a-button(
          :style="isMinimize ? {backgroundColor:'var(--primary-color) !important',color:'#fafafa'} : {}"
          size="small"
          @click="minimize()"
          ) Minimizar
      template(slot="main")
        JitsiVideoCall(
          v-on:call_closed="closeDraggable"
          v-if="room_name != ''"
          :room_name="room_name"
          :username="username"
          :user_is_moderator="true"
          :minimized="minimized")
    side-bar-left
    viewer(
      :filtersUsed="filteredBy"
      v-on:filters-clicked = "showModal()"
      :finishShowRoom="finishShowRoom")
    div(:style="{display: 'flex', flexWrap: 'wrap'}")
      a-button(
          type="primary"
          icon="camera"
          :style="{margin: '-10% auto 5%'}"
          size="small"
          @click="resetDraggableDiv(true)")
          a-icon(
            type="retweet")
      side-bar-right
</template>
<script>
//import atoms components
import AppText from "@/components/atoms/AppText.vue";

//import organisms components
import Layout from "@/components/organisms/Layout.vue";
import Header from "@/components/organisms/Header.vue";

//Import custom components of this component
import SideBarLeft from "@/components/customComponents/ShowRoomComercial/SideBarLeft.vue";
import Viewer from "@/components/customComponents/ShowRoomComercial/Viewer.vue";
import SideBarRight from "@/components/customComponents/ShowRoomComercial/SideBarRight.vue";
import SearchInput from "@/components/customComponents/ShowRoomComercial/SearchInput.vue";

//import components to Jitsi Video Call
import DraggableDiv from "@/components/utils/DraggableDiv";
import JitsiVideoCall from "@/components/utils/JitsiVideoCall";

//Vuex imports
import {
  FETCH_SHOWROOM_COMERCIAL,
  FETCH_COLLECTIONS,
  RESET_SHOWROOM_COMERCIAL,
  UPDATE_VIEWER_ITEM,
  UPDATE_VIEWER_MEDIA,
  THEME
} from "@/store/actions.type";
import { mapGetters } from "vuex";

//Import Api url
import { WEBSOCKET_URL } from "@/common/config.js";
import colorService from "@/common/color.service";

//Import jwt service
import JwtService from "@/common/jwt.service";

export default {
  name: "ShowRoomComercial",
  components: {
    Layout,
    Header,
    SideBarLeft,
    Viewer,
    SideBarRight,
    SearchInput,
    AppText,
    DraggableDiv,
    JitsiVideoCall
  },
  data: function() {
    return {
      isMinimize: false,
      finishShowRoom: false,
      minimized: false,
      is_in_call: true,
      username: "",
      filteredBy: "",
      originX: undefined,
      originY: undefined,
      resetDraggableDivFlag: false
    };
  },
  computed: {
    ...mapGetters(["showRoomComercial", "getThemeName", "user", "collections"]),
    room_name() {
      if (this.showRoomComercial.id && this.showRoomComercial.scheduled_for) {
        return (
          "STN-SHOWROOM-" +
          this.showRoomComercial.id +
          "-" +
          this.showRoomComercial.scheduled_for.replace(
            // eslint-disable-next-line no-useless-escape
            /[&\/\\#,+()$~%.'":*?<>{}]/g,
            ""
          )
        );
      } else {
        return "";
      }
    }
  },
  beforeCreated() {
    this.username = this.user.name;
    this.$connect(
      `${WEBSOCKET_URL}/salesrooms_seller/${
        this.$route.params.id
      }/?token=${JwtService.getToken()}`,
      {
        format: "json"
      }
    );
  },
  async beforeMount() {
    await this.$store.dispatch(THEME, this.user.brand.toLowerCase());
    await colorService.changeTheme(this.getThemeName);
  },
  async mounted() {
    this.username = this.$session.get("user").username;
    await this.$store.dispatch(FETCH_SHOWROOM_COMERCIAL, this.$route.params.id);
    await this.$store.dispatch(FETCH_COLLECTIONS);

    // If no scheduled series, so select a serie from all series list
    if (!this.showRoomComercial.scheduled_series[0]) {
      await this.$store.dispatch(
        UPDATE_VIEWER_ITEM,
        this.collections.results[0]
      );
      await this.$store.dispatch(UPDATE_VIEWER_MEDIA, {
        media: "image",
        url: this.collections.results[0].images[0].url
      });
    } else {
      await this.$store.dispatch(
        UPDATE_VIEWER_ITEM,
        this.showRoomComercial.scheduled_series[0]
      );
      await this.$store.dispatch(UPDATE_VIEWER_MEDIA, {
        media: "image",
        url: this.showRoomComercial.scheduled_series[0].images[0].url
      });
    }
    // Validate if the showroom is finished
    if (this.showRoomComercial.effective_end) {
      this.$router.push({ name: "Dashboard/Panel" });
      this.$message.error("El ShowRoom al cual ingresó ya ha finalizado");
    }
  },
  methods: {
    clickDragButton(e) {
      if (!this.originX && !this.originY) {
        this.originX = e.clientX;
        this.originY = e.clientY;
      }
    },
    resetDraggableDiv(value) {
      this.resetDraggableDivFlag = value;
    },
    showModal() {
      this.$refs.searchInput.showModal();
    },
    minimize() {
      this.isMinimize = !this.isMinimize;
      this.minimized = !this.minimized;
    },
    closeDraggable() {
      this.is_in_call = false;
    },
    async endShowRoom() {
      this.$socket.sendObj({
        type: "product_message",
        product: "Finish ShowRoom"
      });
      await this.$store.dispatch(RESET_SHOWROOM_COMERCIAL);
      this.$router.push({ name: "Dashboard/Panel" });
    },
    showFiltersUsed(filters, query) {
      this.filteredBy = "";
      const {
        products__anti_slip,
        products__color__name,
        products__family__name,
        products__feature__name,
        products__format_cm__name,
        products__is_special,
        products__new,
        products__nuance__name,
        products__rectified,
        products__solutions__name,
        products__use
      } = filters;
      this.filteredBy += "Se filtró por ";

      if (query) {
        this.filteredBy += `palabras de busqueda: "${query}"`;
      }
      if (products__color__name) {
        this.filteredBy += `Color: ${products__color__name}, `;
      }
      if (products__family__name) {
        this.filteredBy += `Familia: ${products__family__name}, `;
      }
      if (products__feature__name) {
        this.filteredBy += `Característica: ${products__feature__name}, `;
      }
      if (products__format_cm__name) {
        this.filteredBy += `Formato: ${products__format_cm__name}, `;
      }
      if (products__nuance__name) {
        this.filteredBy += `Matiz: ${products__nuance__name}, `;
      }
      if (products__solutions__name) {
        this.filteredBy += `Solución: ${products__solutions__name}, `;
      }
      if (products__anti_slip) {
        this.filteredBy += `Antideslizante, `;
      }
      if (products__rectified) {
        this.filteredBy += "Rectificado, ";
      }
      if (products__new) {
        this.filteredBy += "Nuevo, ";
      }
      if (products__is_special) {
        this.filteredBy += "Especial, ";
      }
      if (products__use) {
        this.filteredBy += `Uso: ${products__use}`;
      }
      if (this.filteredBy === "Se filtró por ") {
        this.filteredBy = "";
      }
    }
  }
};
</script>
<style lang="less">
.container-navbar-text {
  color: var(--container-text-color) !important;
}
.container-navbar-text--danger {
  color: var(--error-color) !important;
}
.ant-layout-sider {
  height: 100vh !important;
}
</style>
