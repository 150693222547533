<template lang="pug">
div(v-if="is_in_call")
  div(id="meet" v-show="!minimized")
</template>
<script src="https://meet.jit.si/external_api.js"></script>
<script>
export default {
  name: "JitsiVideoCall",
  props: {
    room_name: {
      type: String,
      default: "Centria Platform Call"
    },
    username: {
      type: String,
      default: "Guest"
    },
    minimized: {
      type: Boolean,
      default: false
    },
    user_is_moderator: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      api: null,
      is_in_call: true
    };
  },
  mounted() {
    this.openRoom();
  },
  destroyed() {
    this.api.dispose();
  },
  methods: {
    startConference() {
      var _this = this;
      try {
        const domain = "meet.jit.si";
        const options = {
          roomName: this.room_name,
          height: "100%",
          width: "100%",
          resize: "both",
          overflow: "auto",
          parentNode: document.querySelector("#meet"),

          configOverwrite: {
            disableSimulcast: false,
            defaultLanguage: "es"
          }
        };
        if (this.user_is_moderator) {
          options.interfaceConfigOverwrite = {
            filmStripOnly: false,
            SHOW_JITSI_WATERMARK: false,
            SHOW_PROMOTIONAL_CLOSE_PAGE: false,
            SHOW_WATERMARK_FOR_GUESTS: false,
            SHOW_POWERED_BY: false,
            SHOW_JITSI_WATERMARK: false,
            INVITATION_POWERED_BY: false,
            SHOW_CHROME_EXTENSION_BANNER: false,
            DEFAULT_REMOTE_DISPLAY_NAME: "Guest",
            SETTINGS_SECTIONS: ["devices", "language"],
            TOOLBAR_BUTTONS: [
              "microphone",
              "camera",
              "closedcaptions",
              "desktop",
              "fullscreen",
              "fodeviceselection",
              "mute-everyone",
              "hangup",
              "chat",
              "videobackgroundblur",
              "etherpad",
              "raisehand",
              "videoquality",
              "filmstrip",
              "tileview",
              "settings"
              // "recording"
            ]
          };
        } else {
          options.interfaceConfigOverwrite = {
            filmStripOnly: false,
            SHOW_JITSI_WATERMARK: false,
            SHOW_PROMOTIONAL_CLOSE_PAGE: false,
            SHOW_WATERMARK_FOR_GUESTS: false,
            SHOW_POWERED_BY: false,
            SHOW_JITSI_WATERMARK: false,
            INVITATION_POWERED_BY: false,
            SHOW_CHROME_EXTENSION_BANNER: false,
            DEFAULT_REMOTE_DISPLAY_NAME: "Guest",
            SETTINGS_SECTIONS: ["devices", "language"],
            TOOLBAR_BUTTONS: [
              "microphone",
              "camera",
              "closedcaptions",
              "desktop",
              "fullscreen",
              "fodeviceselection",
              "hangup",
              "chat",
              "etherpad",
              "raisehand",
              "videoquality",
              "filmstrip",
              "tileview",
              "settings"
            ]
          };
        }

        this.api = new JitsiMeetExternalAPI(domain, options);

        // To show the username of the local user
        this.api.on("videoConferenceJoined", () => {
          _this.api.executeCommand("displayName", _this.username);
        });
        // To alert when a user come in to the room
        this.api.on("participantJoined", object => {
          this.$message.info(`${object.displayName} Se ha unido a la sala`);
        });

        // To alert when a user come in to the room
        // THE EVENT DO NOT RETURN USERNAME
        // this.api.addEventListener("participantLeft", (object) => {
        //   this.$message.info(`${object.id} Ha Salido de la sala`);
        // });

        this.api.on("videoConferenceLeft", () => {
          // TODO This must be transfered to Vuex
          this.$emit("call_closed");
          this.is_in_call = false;
        });
      } catch (error) {
        console.error("Failed to load Jitsi API", error);
      }
    },
    openRoom() {
      // verify the JitsiMeetExternalAPI constructor is added to the global..
      if (window.JitsiMeetExternalAPI) {
        this.startConference();
      } else alert("Jitsi Meet API script not loaded");
    }
  }
};
</script>

<style scoped>
#meet {
  width: 100%;
  height: 100%;
}
</style>
